<template>
  <v-sheet
    max-width="350"
    elevation="2"
    class="mr-3"
  >
    <v-simple-table
      dense
      class="font-weight-medium"
    >
      <template #default>
        <tbody>
          <tr>
            <td>Importe total</td>
            <td
              class="importe"
            >
              {{ importeArticulos|currency }}
            </td>
          </tr>
          <tr>
            <td>Importe de cuotas (mensual)</td>
            <td
              class="importe"
            >
              {{ importeCuotas|currency }}
            </td>
          </tr>
        </tbody>
      </template>
    </v-simple-table>
  </v-sheet>
</template>

<script>
export default {
  props: {
    importeArticulos: {
      type: Number,
      default: 0,
    },
    importeCuotas: {
      type: Number,
      default: 0,
    },
  },
}
</script>

<style scoped>
.importe {
  text-align: right;
}
</style>