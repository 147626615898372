<template>
  <b10-base>
    <b10-toolbar
      :title="title"
      :options="toolbarOptions"
      @click-option="clickToolbarOption"
    />
    <b10-page-content>
      <b10-view-summary
        :item="formattedItem"
        :showing.sync="showingDialogs.moreInfo"
        :loading-data="loadingData"
        :show-alerts="!routeFromBrowserHistory"
      >
        <template slot="extraSubtitle">
          <v-row
            class="my-1"
            justify="center"
          >
            <extra-subtitle
              :importe-articulos="formattedItem.importe_articulos"
              :importe-cuotas="formattedItem.importe_cuotas"
            />
          </v-row>
        </template>
        <template slot="cardActionsLeft">
          <v-chip
            v-if="formattedItem.estado_financiero !== PRESUPUESTOCLI.estadosFinancieros.noNecesitaAprobacion"
            label
            :color="PRESUPUESTOCLI.coloresEstadosFinancieros[formattedItem.estado_financiero]"
            dark
            small
            class="mr-1 mt-1"
          >
            <v-icon
              left
              small
            >
              {{ $vuetify.icons.values.estudioFinanciero }}
            </v-icon>
            {{ PRESUPUESTOCLI.descripcionesEstadosFinancieros[formattedItem.estado_financiero] }}
          </v-chip>
        </template>
      </b10-view-summary>
      <b10-view-details
        :details="details"
        @click-detail="clickDetail"
      />
      <b10-fab-button
        v-if="hasEditPerm(permissions.presupuestocli.id) && presupuestoPendiente"
        :icon="$vuetify.icons.values.edit"
        @click="clickEdit"
      />
    </b10-page-content>
  </b10-base>
</template>

<script>
import _ from '@/utils/lodash'
import { viewPageMixin } from '@/mixins/viewPageMixin'
import ExtraSubtitle from './components/ExtraSubtitle'
import Data from './PresupuestocliViewData'
import { TABLA, PRESUPUESTOCLI } from '@/utils/consts'
import { get } from 'vuex-pathify'

export default {
  components: {
    ExtraSubtitle
  },
  mixins: [viewPageMixin],
  beforeRouteLeave (to, from, next) {
    return this.beforeRouteLeaveBase(to, from, next)
  },
  data () {
    return {
      moreInfoRows: {
        presupuesto: {
          title: 'Presupuesto',
          rows: [
            { name: 'fpresupuesto', filter: this.$options.filters.shortDate },
            'descripcion_sistema',
            'titulo',
            'direccion',
            'ttarifa_descripcion',
            'regimen_fiscal_descripcion',
            'comercial_nombre',
            'agente_nombre',
            'canal_comunicacion_descripcion',
            'canal_conocimiento_descripcion',
            'tipo_riesgo_descripcion',
            'grado_seguridad_descripcion',
          ],
        },
        pago: {
          title: 'Formas y condiciones de pago',
          rows: [
            'condicion_pago_descripcion',
            'fpago_sistema_descripcion',
            'condicion_pago_cuota_desc',
            'forma_pago_cuota_descripcion',
          ],
        },
        comentarios: {
          title: 'Comentarios',
          rows: [
            'observaciones',
            'notas_internas',
          ],
        },
      },
      showingDialogs: {
        moreInfo: false,
      },
      toolbarOptions: {
        irCliente: {
          title: 'Ir al cliente',
          visible: true,
        },
        irClientePotencial: {
          title: 'Ir al cliente potencial',
          visible: true,
        },
        addPlantilla: {
          title: 'Plantilla de artículos',
          visible: true,
          icon: 'plus'
        },
        aceptarPresupuesto: {
          title: 'Aceptar',
          visible: true,
          icon: 'checkAll'
        },
        addContrato: {
          title: 'Contrato',
          visible: true,
          icon: 'plus'
        },
      },
      PRESUPUESTOCLI,
    }
  },
  computed: {
    usuarioIdtecnico: get('usuario/idtecnico'),
    usuarioIdcomercial: get('usuario/idcomercial'),
    usuarioIdvigilante: get('usuario/idvigilante'),
    appComerciales: get('usuario/appComerciales'),
    formattedItem () {
      if (!_.isEmpty(this.item.dataset)) {
        const item = _.cloneDeep(this.item.dataset)
        item.id = item.idpresupuestocli
        item.title = this.$online.presupuestocli.title(this.item.dataset)
        item.subtitle = this.$online.presupuestocli.subtitle(this.item.dataset)
        item.badge = item.est_presupuestocli_descripcion
        if (item.idclasificacion_estado === PRESUPUESTOCLI.clasificacionEstados.aceptado) {
          item.badgeColor = PRESUPUESTOCLI.colores.aceptado
        } else if (item.idclasificacion_estado === PRESUPUESTOCLI.clasificacionEstados.rechazado) {
          item.badgeColor = PRESUPUESTOCLI.colores.rechazado
        } else {
          item.badgeColor = PRESUPUESTOCLI.colores.pendiente
        }
        item.moreInfo = this.buildMoreInfo(this.item.metadata)
        return item
      } else {
        return {}
      }
    },
    presupuestoPendiente () {
      return (
        this.item.dataset?.idclasificacion_estado &&
        PRESUPUESTOCLI.clasificacionEstadosPendientes.includes(this.item.dataset?.idclasificacion_estado)
      )
    },
    presupuestoAceptado () {
      return (
        this.item.dataset?.idclasificacion_estado &&
        PRESUPUESTOCLI.clasificacionEstadosAceptados.includes(this.item.dataset?.idclasificacion_estado)
      )
    },
  },
  async created () {
    await this.initStore()
    await this.loadPage()
  },
  methods: {
    async loadPage () {
      await this.loadItem()
      this.title = this.$online.presupuestocli.title(this.item.dataset)
      if (this.item.dataset.estado_financiero === PRESUPUESTOCLI.estadosFinancieros.pendienteAprobacion && (this.presupuestoPendiente || this.presupuestoAceptado)) {
        this.$alert.showSnackbarWarning(
          'El cliente necesita un estudio financiero',
          { persistent: true, actions: [{ text: 'Añadir', flat: true, handler: this.clickEstudioFinancieroAdd }], }
        )
      }
      await this.loadDetailTotals()
    },
    async loadItem () {
      this.loadingData = true
      try {
        const [dataset, metadata] = await Data.selectPresupuestocli(this, this.routeParams.idpresupuestocli)
        if (dataset) {
          this.$set(this.item, 'dataset', dataset)
          this.$set(this.item, 'metadata', metadata)
        } else {
          this.itemNotFound()
        }
        this.toolbarOptions.irCliente.visible = (
          !!this.item.dataset.idcliente && this.hasViewPerm(this.permissions.cliente.id)
        )
        this.toolbarOptions.irClientePotencial.visible = (
          !!this.item.dataset.idcliente_potencial && this.hasViewPerm(this.permissions.clientePotencial.id)
        )
        this.toolbarOptions.addPlantilla.visible = (
          this.presupuestoPendiente && this.hasEditPerm(this.permissions.presupuestocli.id)
        )
        this.toolbarOptions.aceptarPresupuesto.visible = (
          this.presupuestoPendiente && this.hasEditPerm(this.permissions.presupuestocli.id)
        )
        this.toolbarOptions.addContrato.visible = (
          this.presupuestoAceptado && this.hasInsertPerm(this.permissions.contrato.id)
        )
      } finally {
        this.loadingData = false
      }
    },
    async loadDetailTotals () {
      this.initDetails()
      const resp = await Data.selectDetails(
        this,
        this.routeParams.idpresupuestocli,
        !!this.usuarioIdtecnico,
        !!this.usuarioIdcomercial,
        !!this.usuarioIdvigilante
      )
      // líneas de presupuesto
      if (this.hasViewPerm(this.permissions.lpresupuestocli.id)) {
        const detailLineasPresupuesto = this.addDetail(
          'lineasPresupuesto', 'Artículos y mano de obra', 'Artículos y mano de obra presupuestados', 'lpresupuestocli'
        )
        detailLineasPresupuesto.badge = resp.data.selectLpresupuestocliCount.result.dataset[0].count || 0
      }
      // cuotas
      if (this.hasViewPerm(this.permissions.presupuestoCuota.id)) {
        const detailCuotas = this.addDetail(
          'cuotas', 'Cuotas', 'Cuotas del presupuesto', 'cuotas'
        )
        detailCuotas.badge = resp.data.selectPresupuestoCuotaCount.result.dataset[0].count || 0
      }
      // revisiones
      if (this.hasViewPerm(this.permissions.presupuestocliMant.id)) {
        const detailDatos = this.addDetail(
          'revisiones', 'Revisiones', 'Revisiones de sistema del presupuesto', 'revision'
        )
        detailDatos.badge = resp.data.selectPresupuestocliMantCount.result.dataset[0].count || 0
      }
      // datos de presupuesto
      if (this.hasViewPerm(this.permissions.presupuestocliDato.id)) {
        const detailDatos = this.addDetail(
          'datos', 'Datos', 'Datos personalizados del presupuesto', 'datoPersonalizado'
        )
        detailDatos.badge = resp.data.selectPresupuestoDatoCount.result.dataset[0].count || 0
      }
      // ubicaciones
      if (this.hasViewPerm(this.permissions.lpresupuestocli.id)) {
        const detailUbicaciones = this.addDetail(
          'ubicaciones', 'Ubicaciones', 'Ubicaciones de artículos presupuestados', 'ubicacion'
        )
        detailUbicaciones.badge = resp.data.selectUbiLpresupuestocliCount.result.dataset[0].count || 0
      }
      // contratos
      if (this.hasViewPerm(this.permissions.contrato.id)) {
        const detailCuotas = this.addDetail(
          'contratos', 'Contratos', 'Contratos del presupuesto', 'contratos'
        )
        detailCuotas.badge = resp.data.selectPresupuestocliContratoCount.result.dataset[0].count || 0
      }
      // documentación a enviar
      if (this.hasViewPerm(this.permissions.presupuestocliDocumento.id)) {
        const detailDocumentos = this.addDetail(
          'documentos', 'Documentación', 'Documentación a enviar', 'documentacionEnviar'
        )
        detailDocumentos.badge = resp.data.selectDocumentoCount.result.dataset[0].count || 0
      }
      // sobres
      if (this.hasViewPerm(this.permissions.presupuestocliSobre.id)) {
        const detailSobres = this.addDetail(
          'sobres', 'Sobres', 'Sobres de documentación a enviar', 'sobreAbierto'
        )
        detailSobres.badge = resp.data.selectSobreCount.result.dataset[0].count || 0
      }
      // subsistemas
      if (this.hasViewPerm(this.permissions.presupuestocliSubsis.id)) {
        const detailSubsis = this.addDetail(
          'subsistemas', 'Subsistemas', 'Subsistemas afectados del presupuesto', 'subsistema'
        )
        detailSubsis.badge = resp.data.selectPresupuestocliSubsisCount.result.dataset[0].count || 0
      }
      // ficheros
      if (this.hasViewPerm(this.permissions.presupuestocliAdjunto.id)) {
        const detailFicheros = this.addDetail(
          'adjuntos', 'Adjuntos', 'Fotografías y ficheros adjuntos', 'attach'
        )
        detailFicheros.badge = resp.data.selectFicheroCount.result.dataset[0].count || 0
      }
      // operaciones
      if (this.appComerciales && this.hasViewPerm(this.permissions.operaciones.id)) {
        const detailOperacionCRM = this.addDetail(
          'operacionCRM', 'Operaciones', 'Operaciones relacionadas con el presupuesto', 'operacionCRM'
        )
        detailOperacionCRM.badge = resp.data.selectOperacioncrmCount.result.dataset[0].count || 0
      }
    },
    clickDetail (data) {
      if (data.detail.name === 'lineasPresupuesto') {
        this.$appRouter.push({
          name: 'presupuestos__lineas__lpresupuestocli-list',
          params: {
            idpresupuestocli: this.routeParams.idpresupuestocli,
          },
        })
      } else if (data.detail.name === 'cuotas') {
        this.$appRouter.push({
          name: 'presupuestos__cuotas__presupuestocuota-list',
          params: {
            idpresupuestocli: this.routeParams.idpresupuestocli,
          },
        })
      } else if (data.detail.name === 'revisiones') {
        this.$appRouter.push({
          name: 'presupuestos__revisiones__presupuestocli-mant-list',
          params: {
            idpresupuestocli: this.routeParams.idpresupuestocli,
          },
        })
      } else if (data.detail.name === 'datos') {
        this.$appRouter.push({
          name: 'datos__presupuestos-dato-list',
          params: {
            id: this.routeParams.idpresupuestocli,
            idtabla: TABLA.presupuestocli.idtabla,
            readonly: !this.presupuestoPendiente,
          },
        })
      } else if (data.detail.name === 'ubicaciones') {
        this.$appRouter.push({
          name: 'presupuestos__ubicaciones__ubi-lpresupuestocli-list',
          params: {
            idpresupuestocli: this.routeParams.idpresupuestocli,
          },
        })
      } else if (data.detail.name === 'contratos') {
        this.$appRouter.push({
          name: 'contratos__presupuestos-contrato-list',
          params: {
            idpresupuestocli: this.routeParams.idpresupuestocli,
          },
        })
      } else if (data.detail.name === 'documentos') {
        this.$appRouter.push({
          name: 'documentacionenviar__presupuestos-documento-list',
          params: {
            idpresupuestocli: this.routeParams.idpresupuestocli,
          },
        })
      } else if (data.detail.name === 'sobres') {
        this.$appRouter.push({
          name: 'sobres__presupuestos-sobre-list',
          params: {
            id: this.routeParams.idpresupuestocli,
            idtabla: TABLA.presupuestocli.idtabla,
          },
          query: {
            ididioma: this.item.dataset.ididioma,
          },
        })
      } else if (data.detail.name === 'subsistemas') {
        this.$appRouter.push({
          name: 'presupuestos__subsistemas__presupuestocli-subsis-list',
          params: {
            idpresupuestocli: this.routeParams.idpresupuestocli,
          },
        })
      } else if (data.detail.name === 'adjuntos') {
        this.$appRouter.push({
          name: 'ficheros__presupuestos-fichero-list',
          params: {
            id: this.routeParams.idpresupuestocli,
            idtabla: TABLA.presupuestocli.idtabla,
            readonly: !this.presupuestoPendiente,
          },
        })
      } else if (data.detail.name === 'operacionCRM') {
        this.$appRouter.push({
          name: 'operacionescrm__presupuestos-operacioncrm-list',
          params: {
            idpresupuestocli: this.routeParams.idpresupuestocli,
          },
        })
      }
    },
    async clickToolbarOption (option) {
      if (option === this.toolbarOptions.irCliente) {
        this.$appRouter.push({
          name: 'clientes__cliente-view',
          params: {
            idcliente: this.item.dataset.idcliente,
          },
        })
      } else if (option === this.toolbarOptions.irClientePotencial) {
        this.$appRouter.push({
          name: 'clientespotenciales__cliente-potencial-view',
          params: {
            idcliente_potencial: this.item.dataset.idcliente_potencial,
          },
        })
      } else if (option === this.toolbarOptions.addPlantilla) {
        this.$appRouter.push({
          name: 'presupuestos__plantilla-articulo-add',
          params: {
            idpresupuestocli: this.routeParams.idpresupuestocli,
          },
        })
      } else if (option === this.toolbarOptions.aceptarPresupuesto) {
        this.$appRouter.push({
          name: 'presupuestos__presupuestocli-aceptar',
          params: {
            idpresupuestocli: this.routeParams.idpresupuestocli,
          },
        })
      } else if (option === this.toolbarOptions.addContrato) {
        this.$appRouter.push({
          name: 'presupuestos__presupuestocli-contrato-add',
          params: {
            idpresupuestocli: this.routeParams.idpresupuestocli,
          },
        })
      }
    },
    clickEdit () {
      this.$appRouter.push({
        name: 'presupuestos__presupuestocli-edit',
        params: {
          idpresupuestocli: this.routeParams.idpresupuestocli,
        },
      })
    },
    clickEstudioFinancieroAdd () {
      if (this.item.dataset.idcliente) {
        this.$appRouter.push({
          name: 'estudiosfinancieros__clientes-estudio-financiero-add',
          params: {
            idcliente: this.item.dataset.idcliente,
          },
        })
      } else {
        this.$appRouter.push({
          name: 'estudiosfinancieros__clientes-potenciales-estudio-financiero-add',
          params: {
            idcliente_potencial: this.item.dataset.idcliente_potencial,
          },
        })
      }
    },
  }
}
</script>
