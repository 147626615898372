import { APIFilter } from '@/utils/api'
import { TABLA } from '@/utils/consts'

export default {
  async selectPresupuestocli (Vue, idpresupuestocli) {
    const apiFilter = new APIFilter()
      .addExact('idpresupuestocli', idpresupuestocli)
      .addGT('idest_presupuestocli', 0)
    const resp = await Vue.$api.call('presupuestocli.select', { filter: apiFilter })
    return [resp.data.result.dataset[0], resp.data.result.metadata]
  },
  async selectDetails (Vue, idpresupuestocli, esTecnico, esComercial, esVigilante) {
    let apiCalls = []
    const apiFilter = new APIFilter()
      .addExact('idpresupuestocli', idpresupuestocli)
      .addGT('estado', 0)
    apiCalls.push({ name: 'selectLpresupuestocliCount', method: 'lpresupuestocli.select', params: { filter: apiFilter, wrapper: 'count' } })
    apiCalls.push({ name: 'selectPresupuestoCuotaCount', method: 'presupuestoCuota.select', params: { filter: apiFilter, wrapper: 'count' } })
    apiCalls.push({ name: 'selectPresupuestocliMantCount', method: 'presupuestocliMant.select', params: { filter: apiFilter, wrapper: 'count' } })
    apiCalls.push({ name: 'selectPresupuestocliSubsisCount', method: 'presupuestocliSubsis.select', params: { filter: apiFilter, wrapper: 'count' } })
    apiCalls.push({ name: 'selectUbiLpresupuestocliCount', method: 'ubiLpresupuestocli.select', params: { filter: apiFilter, wrapper: 'count' } })
    apiCalls.push({ name: 'selectPresupuestocliContratoCount', method: 'contrato.select', params: { filter: apiFilter, wrapper: 'count' } })
    const apiFilterPorTabla = new APIFilter()
      .addExact('id', idpresupuestocli)
      .addGT('estado', 0)
      .addExact('idtabla', TABLA.presupuestocli.idtabla)
    apiCalls.push({ name: 'selectDocumentoCount', method: 'documento.select', params: { filter: apiFilterPorTabla, wrapper: 'count' } })
    apiCalls.push({ name: 'selectSobreCount', method: 'sobre.select', params: { filter: apiFilterPorTabla, wrapper: 'count' } })
    apiCalls.push({ name: 'selectPresupuestoDatoCount', method: 'dato.select', params: { filter: apiFilterPorTabla, wrapper: 'count' } })
    const apiFilterCRM = new APIFilter()
      .addExact('idpresupuestocli', idpresupuestocli)
    apiCalls.push({ name: 'selectOperacioncrmCount', method: 'operacioncrm.select', params: { filter: apiFilterCRM, wrapper: 'count' } })
    apiCalls.push(Vue.$online.fichero.ApiCallSelectCount(idpresupuestocli, TABLA.presupuestocli.idtabla, esTecnico, esComercial, esVigilante))
    return await Vue.$api.batchCall(apiCalls)
  },
}
